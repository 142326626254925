import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const RecordingsTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <p>
                And the God of peace Himself sanctify you wholly, and may your
                spirit and soul and body be preserved complete, without blame,
                at the coming of our Lord Jesus Christ.
              </p>
              <p>1 Thessalonians 5:23</p>
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
              <p className="is-size-4 has-text-left has-text-weight-bold">
                Young People
              </p>
              <br></br>
              <p className="is-size-4 has-text-left">
                Taking Care of One’s Mental Health – Brother AT and Sister VH
              </p>
              <p className="is-size-5 has-text-left">
                June 2021 Summer School of Truth Workshops
              </p>
              <br></br>
              <iframe
                src="https://www.youtube.com/embed/_zo8kzUHERk"
                frameborder="0"
                allow="encrypted-media"
                allowfullscreen
                width="560"
                height="315"
                title="video"
              />
              <br></br>
              <p className="is-size-5 has-text-left">
                Session for Young People
              </p>
              <br></br>
              <iframe
                src="https://www.youtube.com/embed/yHl16MR61ng"
                frameborder="0"
                allow="encrypted-media"
                allowfullscreen
                width="560"
                height="315"
                title="video"
              />
              <br></br>
              <p className="is-size-5 has-text-left">
                Session for Parents and Serving Ones
              </p>
              <br></br>
              <hr></hr>
              <br></br>
              <p className="is-size-4 has-text-left has-text-weight-bold">
                College Students
              </p>
              <br></br>
              <p className="is-size-4 has-text-left">
                Mental Health Fireside Chat – Brothers RS, RM, MR, and AT
              </p>
              <p className="is-size-5 has-text-left">
                June 2020 European University Conference Fireside Chat
              </p>
              <br></br>
              <iframe
                src="https://www.youtube.com/embed/tBkdZzu2om8"
                frameborder="0"
                allow="encrypted-media"
                allowfullscreen
                width="560"
                height="315"
                title="video"
              />
              <br></br>
              <br></br>
              <p className="is-size-4 has-text-left">
                Mental Health Fireside Chat – Brothers RS, AT, and MS
              </p>
              <p className="is-size-5 has-text-left">
                April 2021 European University Conference Fireside Chat
              </p>
              <br></br>
              <iframe
                src="https://www.youtube.com/embed/m04N65GqwOw"
                frameborder="0"
                allow="encrypted-media"
                allowfullscreen
                width="560"
                height="315"
                title="video"
              />
              <br></br>
              <br></br>
              <p className="is-size-4 has-text-left">
                Bienestar y Salud Mental – Hermano AT
              </p>
              <p className="is-size-5 has-text-left">
                Mayo 2021 Jóvenes Cristianos Universitarios Comunión Especial
              </p>
              <br></br>
              <iframe
                src="https://www.youtube.com/embed/Rekk4nvuUBs"
                frameborder="0"
                allow="encrypted-media"
                allowfullscreen
                width="560"
                height="315"
                title="video"
              />
              <br></br>
              <p className="is-size-5 has-text-left">Español</p>
              <br></br>
              <iframe
                src="https://www.youtube.com/embed/z4XW3SNS4Xw"
                frameborder="0"
                allow="encrypted-media"
                allowfullscreen
                width="560"
                height="315"
                title="video"
              />
              <br></br>
              <p className="is-size-5 has-text-left">Inglés</p>
                <br></br>
                <p className="is-size-4 has-text-left">
                Mental Health Special Fellowship – Brother AT
                </p>
                <p className="is-size-5 has-text-left">
                July 2022 California College Training
                </p>
                <br></br>
                <iframe
                  src="https://www.youtube.com/embed/8mFqlVomHMg?si=HtbB7LQCwSZaWQ7W"
                  frameborder="0"
                  allow="encrypted-media"
                  allowfullscreen
                  width="560"
                  height="315"
                  title="video"
                />
                <br></br>
                <br></br>
                <p className="is-size-4 has-text-left">
                Mental Health Special Fellowship – Brother AT
                </p>
                <p className="is-size-5 has-text-left">
                July 2023 North America College Training
                </p>
                <br></br>
                <iframe
                  src="https://www.youtube.com/embed/4pk_FdeDbsM?si=VjHSafJrxuSi5O06"
                  frameborder="0"
                  allow="encrypted-media"
                  allowfullscreen
                  width="560"
                  height="315"
                  title="video"
                />
              <br></br>
              <br></br>
              <p className="is-size-4 has-text-left">
              Establishing the Souls of the Saints – Brother DK
                </p>
                <p className="is-size-5 has-text-left">
                April 2024 Mental Health Seminar
                </p>
                <br></br>
              <iframe
                src="https://drive.google.com/file/d/1OhieJg6N3QYZv-BsD5AYNlB9OezdsXXM/preview"
                width="560"
                height="315"
                allow="autoplay"
              />
              <br></br>
              <br></br>
              <hr></hr>
              <br></br>
              <p className="is-size-4 has-text-left has-text-weight-bold">
                Families
              </p>
              <br></br>
              <p className="is-size-4 has-text-left">
                Supporting Our Families and Young People Amid the Challenge of
                the Present Situation
              </p>
              <p className="is-size-4 has-text-left">- Brothers RS and AT</p>
              <p className="is-size-5 has-text-left">
                November 2020 DFW Special Fellowship
              </p>
              <a
                href="https://www.churchinplano.org/2020/11/2020-nov-special-fellowship/"
                class="link-primary"
              >
                (Link to Audio)
              </a>
              <br></br>
              <br></br>
              <hr></hr>
              <br></br>
              <p className="is-size-4 has-text-left has-text-weight-bold">
                Full-Timers
              </p>
              <br></br>
              <p className="is-size-4 has-text-left">
                Mental Health Session – Brothers RS, DK, and AT
              </p>
              <p className="is-size-5 has-text-left">
                October 2020 National Student Work Fellowship
              </p>
              <br></br>
              <iframe
                src="https://www.youtube.com/embed/niFwLpPX8RA"
                frameborder="0"
                allow="encrypted-media"
                allowfullscreen
                width="560"
                height="315"
                title="video"
              />
              <br></br>
              <br></br>
              <p className="is-size-4 has-text-left">
                Mental Health Session – Brothers DK, AT, and RS
              </p>
              <p className="is-size-5 has-text-left">
                May 2022 Campus Team Retreat
              </p>
              <br></br>
              <iframe
                src="https://www.youtube.com/embed/viSu5PheuiE"
                frameborder="0"
                allow="encrypted-media"
                allowfullscreen
                width="560"
                height="315"
                title="video"
              />

              {/* <h4 className="is-size-5 has-text-weight-semibold">
                No More Meltdowns by Jed Baker, Ph.D. random to save
              </h4>
              <p>
                Self-help book for parents of children with challenging and disruptive
                behaviors most commonly recommended by mental health professionals{" "}
              </p>
              <p>
                <a
                  href="https://www.amazon.com/gp/product/B00U10WU0C/ref=dbs_a_def_rwt_hsch_vapi_tkin_p1_i1"
                  target="_blank"
                  rel="noferrer,noopener"
                >
                  https://www.amazon.com/gp/product/B00U10WU0C/ref=dbs_a_def_rwt_hsch_vapi_tkin_p1_i1
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

RecordingsTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const Recordings = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <RecordingsTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

Recordings.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Recordings;

export const RecordingsQuery = graphql`
  query RecordingsQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
